<template>
    <v-container fluid class="px-10">
      <v-tabs v-model="selectedDomain" light>
        <v-tab v-for="domain in domains" :key="domain">{{ domain }}</v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="selectedDomain" class="mt-10">
        <v-tab-item v-for="domain in domains" :key="domain">
          <v-row>
            <!-- Dynamically generate columns for each status within the domain -->
            <v-col cols="12" md="3" v-for="status in statuses[domain]" :key="status" class="mb-4 pb-10">
              <v-card class="mx-1 mt-4" elevation="4">
                <v-card-title>
                  <span>{{ status }}</span>
                  <v-spacer></v-spacer>
                  <v-chip class="ml-2" color="primary" small>
                    {{ filteredConversations(domain, status).length }}
                  </v-chip>
                </v-card-title>
                <v-divider></v-divider>
                <div class="pa-3 conversation-container">
                    <template v-for="conversation in filteredConversations(domain, status)">
                        <v-card :key="conversation.id" 
                                class="mb-3 conversation-card" 
                                :class="{'green lighten-5': conversation.readStatus === 'unread'}" 
                                @click="openConversation(conversation.id)" 
                                outlined>
                            <v-card-title class="d-flex justify-space-between align-center">
                                <span class="font-weight-bold customer-name">
                                  {{ conversation.customer ? conversation.customer.name : 'Unknown' }}
                                </span>
                                <span class="caption grey--text date">
                                  {{ formatDate(conversation.lastUpdatedTimestamp.toDate()) }}
                                </span>
                            </v-card-title>
                            <v-card-subtitle class="conversation-id">
                              {{ conversation.senderNumber || conversation.id }}
                            </v-card-subtitle>
                            <v-card-subtitle class="message-preview">
                              {{ truncateMessagePreview(conversation.messagePreview, 80) }}
                            </v-card-subtitle>
                             <!-- Include order numbers -->
                            <v-card-subtitle v-if="conversation.orders && conversation.orders.length !== 0">
                                <v-chip small v-for="order in conversation.orders" :key="order.order_id">{{ order }}</v-chip>
                            </v-card-subtitle>
                        </v-card>
                    </template>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </template>
  
  <script>
  import moment from 'moment';
  import db from '../../../components/firebaseInit';
  
  export default {
    name: "CrmDashboard",
    data() {
      return {
        selectedDomain: null,
        domains: ['Sales', 'Manufacturing', 'Deliveries'],
        statuses: {
          Sales: ['New Lead', 'Contact', 'Quote Sent', 'Order Confirmed'],
          Manufacturing: ['Production Started', "All Items Assigned" ,'Production Completed'],
          Deliveries: ['Pending', 'In Transit', 'Delivered'],
        },
        conversations: [],
      };
    },
    created() {
      this.fetchConversations();
    },
    methods: {
      fetchConversations() {
        const docRef = db.collection('cms_conversations').orderBy('lastUpdatedTimestamp', 'desc');
        docRef.onSnapshot((snapshot) => {
          this.conversations = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        });
      },
      filteredConversations(domain, status) {
        return this.conversations.filter(
          (conversation) => conversation.domain === domain && conversation.status === status
        );
      },
      openConversation(id) {
        this.$router.push({ name: 'conversation-detail', params: { conversation_id: id } });
      },
      truncateMessagePreview(text, length) {
        return text.length > length ? text.substring(0, length) + '...' : text;
      },
      formatDate(dateTime) {
        const today = moment().startOf('day');
        const messageDate = moment(dateTime);
        
        if (messageDate.isSame(today, 'd')) {
            // If the message was sent today, display the time
            return messageDate.format('LT');
        } else {
            // If the message was sent on a previous date, display the date
            return messageDate.format('MMM D');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-card {
    display: flex;
    flex-direction: column;
  }


  .v-card-title {
    display: flex; /* Adjust card title to use flex layout */
    align-items: center; /* Center align items vertically */
    font-size: 16px;
    padding: 16px;
    margin-bottom: 4px; 
  }

  .conversation-container {
    min-height: 520px;
    max-height: 520px; /* Set a maximum height */
    overflow-y: auto; /* Make it scrollable */
  }

  .conversation-card {
    padding: 8px; /* Reduce padding */
    max-height: 240px;
  }

  .v-card-title, .v-card-subtitle {
    padding-left: 8px; /* Reduce padding */
    padding-right: 8px; /* Reduce padding */
  }

  .v-card-actions {
    padding-left: 8px; /* Reduce padding */
    padding-bottom: 4px; /* Reduce padding */
  }

  .customer-name {
    font-size: 14px; /* Slightly smaller font size */
  }

  .conversation-id {
    font-size: 12px;
    color: #757575; 
  }

  .message-preview {
    font-size: 12px; /* Adjusted font size */
    color: #757575;
    padding-top: 0 !important;
  }

  .date {
    font-size: 11px !important; /* Smaller font size for date */
  }

</style>